(function($, root, undefined) {

    $(function() {

        'use strict'; // DOM ready, take it away
    


		// smooth scrolling anchors
        function filterPath(string) {
            return string
                .replace(/^\//, '')
                .replace(/(index|default).[a-zA-Z]{3,4}$/, '')
                .replace(/\/$/, '');
        }


      



        var locationPath = filterPath(location.pathname);
        var scrollElem = scrollableElement('html', 'body'); 

        // use the first element that is "scrollable"
        function scrollableElement(els) {
            for (var i = 0, argLength = arguments.length; i < argLength; i++) {
                var el = arguments[i],
                    $scrollElement = $(el);
                if ($scrollElement.scrollTop() > 0) {
                    return el;
                } else {
                    $scrollElement.scrollTop(1);
                    var isScrollable = $scrollElement.scrollTop() > 0;
                    $scrollElement.scrollTop(0);
                    if (isScrollable) {
                        return el;
                    }
                }
            }
            return [];
        } 

        //display scroll up button
        var target = 200; // distance to scroll before triggering
        var timeout = null; // timeout used to prevent constant running of function, runs only every 250ms max

        $(window).scroll(function() {
            if (!timeout) {
                timeout = setTimeout(function() {
                    clearTimeout(timeout);
                    timeout = null;
                    if ($(window).scrollTop() >= target) {
                        $('.scroll-to-top').addClass('active');
                    }
                }, 250);
            }
        });  
        
        //remove whitespace
        $.fn.removeSpace = function(){
          $(this).contents().filter(function() {
            return this.nodeType === 3; 
          }).remove();
        };
        //use it
        // $('fieldset').removeSpace();


        //header height
        function setHeaderHeight(){
            var headerHeight = $('.header').outerHeight(true);
            //set spacer same height as header
            $('.spacer').css('padding-bottom', headerHeight + 'px');
        }

        setHeaderHeight();

        $(window).load(function() {
            setHeaderHeight();
        });

        $(window).resize(function(event) {
            setHeaderHeight();
        });

        jQuery('#question2').hide();
        jQuery('#question3').hide();
        jQuery('#question4').hide();
        jQuery('#question5').hide();

        jQuery('#question2-bottom').hide();
        jQuery('#question3-bottom').hide();
        jQuery('#question4-bottom').hide();
        jQuery('#question5-bottom').hide();

        var Jdebt = "";
        var Jdebts = "";
        var Jemployment = "";
        var Jincome = "";

        jQuery('.ans1').on('click', function() {
            Jdebt = jQuery(this).attr('value');
            jQuery('#question1').fadeOut(100);
            jQuery('#question2').fadeIn(1000);
            jQuery('#question1-bottom').fadeOut(100);
            jQuery('#question2-bottom').fadeIn(1000);
            jQuery(".progress").css("width", "40%");
            jQuery(".progress-numbers").html("2 of 5");
            jQuery('#c1').val(Jdebt);
            jQuery('#journey-title').hide();
        });

        jQuery('.ans2').on('click', function() {
            Jdebts = jQuery(this).attr('value');
            jQuery('#question2').fadeOut(100);
            jQuery('#question3').fadeIn(1000);
            jQuery('#question2-bottom').fadeOut(100);
            jQuery('#question3-bottom').fadeIn(1000);
            jQuery(".progress").css("width", "60%");
            jQuery(".progress-numbers").html("3 of 5");
            jQuery('#c2').val(Jdebts);
        });

        jQuery('.ans3').on('click', function() {
            Jemployment = jQuery(this).attr('value');
            jQuery('#question3').fadeOut(100);
            jQuery('#question4').fadeIn(1000);
            jQuery('#question3-bottom').fadeOut(100);
            jQuery('#question4-bottom').fadeIn(1000);
            jQuery(".progress").css("width", "80%");
            jQuery(".progress-numbers").html("4 of 5");
        });

        jQuery('.ans4').on('click', function() {
            Jincome = jQuery(this).attr('value');
            jQuery('#question4').fadeOut(100);
            jQuery('#question5').fadeIn(1000);
            jQuery('#question4-bottom').fadeOut(100);
            jQuery('#question5-bottom').fadeIn(1000);
            jQuery(".progress").css("width", "100%");
            jQuery(".progress-numbers").html("5 of 5");
            jQuery('#c3').val(Jincome);
           
            jQuery('#debtplans').hide();
            jQuery('#calculator').hide();
            jQuery('#getstarted').hide();
            jQuery('#icon-section').hide();
            jQuery('#journey-bottom').hide();

            var newMonthly = 20/100 * Jincome;
            var writeoff = newMonthly * 60;
            var newWriteoff = Jdebt - writeoff;

            var percent = newWriteoff / Jdebt * 100;
            newWriteoff = Math.round(newWriteoff);
            percent = Math.round(percent);

            console.log(newWriteoff);
            console.log(percent);

            if (percent > 81 || percent < 0) {
                percent = 81;
            }

            if (newWriteoff < 0) {
                var percenttoGet = 81;
                newWriteoff = (percenttoGet / 100) * Jdebt;
                newWriteoff = Math.round(newWriteoff);
            }

            jQuery('#debtamount').append(addCommas(Jdebt));
            jQuery('#resultamount').append(addCommas(newWriteoff));
            jQuery('#writeoffpercent').append(percent);

            jQuery('#debtamount-bottom').append(addCommas(Jdebt));
            jQuery('#resultamount-bottom').append(addCommas(newWriteoff));
            jQuery('#writeoffpercent-bottom').append(percent);


        });

        jQuery('.back2').on('click', function() {
            jQuery('#question2').fadeOut(100);
            jQuery('#question1').fadeIn(1000);
            jQuery('#question2-bottom').fadeOut(100);
            jQuery('#question1-bottom').fadeIn(1000);
            jQuery(".progress").css("width", "20%");
            jQuery(".progress-numbers").html("1 of 5");
        });

        jQuery('.back3').on('click', function() {
            jQuery('#question3').fadeOut(100);
            jQuery('#question2').fadeIn(1000);
            jQuery('#question3-bottom').fadeOut(100);
            jQuery('#question2-bottom').fadeIn(1000);
            jQuery(".progress").css("width", "40%");
            jQuery(".progress-numbers").html("2 of 5");
        });

        jQuery('.back4').on('click', function() {
            jQuery('#question4').fadeOut(100);
            jQuery('#question3').fadeIn(1000);
            jQuery('#question4-bottom').fadeOut(100);
            jQuery('#question3-bottom').fadeIn(1000);
            jQuery(".progress").css("width", "60%");
            jQuery(".progress-numbers").html("3 of 5");
        });

        jQuery('#continue').on('click', function() {

            var debt = jQuery('#amountdebt').find(":selected").val();
            var monthly = jQuery('#monthly').find(":selected").val();
            var creditors = jQuery('#creditors').find(":selected").val();
            var employment = jQuery('#employment').find(":selected").val();

            var Jdebt = debt;
            var Jdebts = creditors;
            var Jincome = monthly;

            jQuery('#c1').val(Jdebt);
            jQuery('#c2').val(Jdebts);
            jQuery('#c3').val(Jincome);

            var newMonthly = 20/100 * Jincome;
            var writeoff = newMonthly * 60;
            var newWriteoff = Jdebt - writeoff;

            var percent = newWriteoff / Jdebt * 100;
            newWriteoff = Math.round(newWriteoff);
            percent = Math.round(percent);

            console.log(newWriteoff);
            console.log(percent);

            if (percent > 81 || percent < 0) {
                percent = 81;
            }

            if (newWriteoff < 0) {
                var percenttoGet = 81;
                newWriteoff = (percenttoGet / 100) * Jdebt;
                newWriteoff = Math.round(newWriteoff);
            }

            jQuery('#debtamount-bottom').append(addCommas(Jdebt));
            jQuery('#resultamount-bottom').append(addCommas(newWriteoff));
            jQuery('#writeoffpercent-bottom').append(percent);

            jQuery('#question1-bottom').fadeOut(100);
            jQuery('#question2-bottom').fadeOut(100);
            jQuery('#question3-bottom').fadeOut(100);
            jQuery('#question4-bottom').fadeOut(100);
            jQuery('#question5-bottom').fadeIn(1000);
        });






        jQuery('.left a').hide();
        jQuery('#a7-btn').show();
        jQuery('#a7-btn').addClass('disabled');
        jQuery('#finish i').hide();
        jQuery('#check-two').hide();
        jQuery('.usps').hide();

        jQuery('#contact-details').hide();
        jQuery('#check-results').hide();

        jQuery('#check').prop('disabled', true);

        jQuery('#incomeselect').on('change', function() {
            if(jQuery(this).val() != '') {
                jQuery('#check').addClass('hide');
                jQuery('#check-two').show();
            }
         });
         console.log(window.location.pathname);
            if (window.location.pathname == "/shortform/form/") {
                  $('.head-link').hide();
            } else {
                  $('.head-link').show();
            }


         jQuery('#check-two').on('click', function() {
            jQuery('#finance-details').hide();
            jQuery('#check-results').show();
            var resultDebt = jQuery('#debt').val();
            var resultCreds = jQuery('#creditors').val();
            var resultIncome = jQuery('#incomeselect').val();

            var newMonthly = 20/100 * resultIncome;
            var writeoff = newMonthly * 60;
            var newWriteoff = resultDebt - writeoff;

            var percent = newWriteoff / resultDebt * 100;
            newWriteoff = Math.round(newWriteoff);
            percent = Math.round(percent);

            console.log(newWriteoff);
            console.log(percent);

            if (percent > 81 || percent < 0) {
                percent = 81;
            }

            if (newWriteoff < 0) {
                var percenttoGet = 81;
                newWriteoff = (percenttoGet / 100) * resultDebt;
                newWriteoff = Math.round(newWriteoff);
            }

            jQuery('#debtamount').append(addCommas(resultDebt));
            jQuery('#resultamount').append(addCommas(newWriteoff));
            jQuery('#writeoffpercent').append(percent);
        });

        jQuery('#submit').on('click', function() {
            jQuery('#check-results').hide();
            jQuery('#contact-details').show();
            jQuery('#side-image').hide();
            jQuery('.usps').show();
            jQuery('#firstname-error').hide();
        });

        jQuery('#phone1').keyup(function(){
            var phone = jQuery('#phone1').val();
            
           if (phone.length === 11) {
            console.log('val is 11');
               jQuery('#phone1').blur();
              
           }
          });

        if (jQuery('#debt').val() > 0) {
            jQuery('#debt').next('.button').show();
        }

        if (jQuery('#creditors').val() > 0) {
            jQuery('#creditors').next('.button').show();
        }

        if (jQuery('#incomeselect').val() > 0) {
            jQuery('#incomeselect').next('.button').show();
        }

        jQuery('#debt').on('change', function() {
            var selectval = jQuery(this).find(":selected").val();
            var nextbtn = jQuery(this).next('.button');
            console.log(nextbtn);
            if (jQuery(this).hasClass('address')) {
                nextbtn.show();
            } else {
            if (selectval != "") {
                console.log('herenow');
                nextbtn.show();
                nextbtn.trigger( "click" );
            }
        }
        });

        jQuery('#creditors').on('change', function() {
            var selectval = jQuery(this).find(":selected").val();
            var nextbtn = jQuery(this).next('.button');
            console.log(nextbtn);
            if (jQuery(this).hasClass('address')) {
                nextbtn.show();
            } else {
            if (selectval != "") {
                console.log('herenow');
                nextbtn.show();
                nextbtn.trigger( "click" );
            }
        }
        });

        jQuery('#incomeselect').on('change', function() {
            var selectval = jQuery(this).find(":selected").val();
            var nextbtn = jQuery(this).next('.button');
            console.log(nextbtn);
            if (jQuery(this).hasClass('address')) {
                nextbtn.show();
            } else {
            if (selectval != "") {
                console.log('herenow');
                nextbtn.show();
                nextbtn.trigger( "click" );
            }
        }
        });

        jQuery('#age').on('change', function() {
            var selectval = jQuery(this).find(":selected").val();
            var nextbtn = jQuery(this).next('.button');
            console.log(nextbtn);
            if (jQuery(this).hasClass('address')) {
                nextbtn.show();
            } else {
            if (selectval != "") {
                console.log('herenow');
                nextbtn.show();
                //nextbtn.trigger( "click" );
            }
        }
        });

        jQuery('#lastname').on('input', function() {
            jQuery('#a5-btn').show();
            console.log('kofi')
        });


        jQuery('#email').on('input', function() {
            var inputval = jQuery(this).val();
            var inputid = jQuery(this).attr('id');
            console.log(inputid);
            console.log(inputval + "input");
            var inputnextbtn = jQuery(this).next('.button');
            console.log(inputnextbtn);
            if (inputval != "") {
                console.log('herenowinput');
                inputnextbtn.show();
            }
        });

        function addCommas(x) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        }

        jQuery('.resultq').hide();


        jQuery('#calc').on("click", function() {
            var debt = jQuery('#amountdebt').find(":selected").val();
            var monthly = jQuery('#monthly').find(":selected").val();
            var creditors = jQuery('#creditors').find(":selected").val();
            var employment = jQuery('#employment').find(":selected").val();
            console.log(debt);
            console.log(monthly);

            if (debt !== "Please select…" && monthly !== "Please select…" && creditors !== "Please select…") {

    

            var result = (monthly * 60) - debt;
            result = Math.abs(result);

            console.log(result);


            var resultpercent = (result / debt) * 100;

            if (result == NaN || result == 0) {
                jQuery('.results-total').html("<span>£0</span>");
            } else {
                jQuery('.results-total').html("<span>£" + result + "</span>");
            }

            jQuery('#debtamount').html("£" + addCommas(debt));
            jQuery('#resultamount').html("£" + addCommas(result));
            jQuery('#resultpercent').html(resultpercent + "%");

            if (debt != 0 & monthly != "") {
                jQuery('.resultq').show();
            }

            var href = jQuery('#continue').attr('href');
            var newhref = "";
            newhref += href;
            newhref += "?debt=" + debt + "&monthly=" + monthly + "&creditors=" + creditors + "/#a4";
            console.log(newhref);

            jQuery('#continue').attr('href', newhref);

        } else {
            jQuery('resultq').html("<h3>All fields required</h3>");
            jQuery('resultq').show();
        }
            
        });

        jQuery("#fullname").focus(function() {
            console.log('in');
        }).blur(function() {
            console.log('out');
            var fullname = jQuery('#fullname').val();
            var res = fullname.split(" ");
            var firstname = res[0];
            var lastname = res[1];

            jQuery('#firstname').attr('value', firstname);
            jQuery('#lastname').attr('value', lastname);

            console.log(jQuery('#firstname').val());
        });

        jQuery('.address').hide();
        
        jQuery('#postcode').on("input", function(e) {
            let el = jQuery(this);
            var postcode = jQuery('#postcode').val();
            console.log('#postcode');
            if (postcode == "") {
                e.preventDefault();
                console.log('it is empty');
                return;
            } else {
                e.preventDefault();
                console.log('ajax look up');
                // return;
                
                get_addresses(postcode, el);
            }
        })
    
        function get_addresses(postcode, el) {
            
            //disable_postcode_submit_button(el);
    
            console.log('get addresses', postcode)
            console.log('get addresses', el)
            jQuery('#postcode-error').hide();
            jQuery.ajax({
                url: 'https://api.switcheroo.deals/address-lookup.php',
                type: 'POST',
                enctype: 'multipart/form-data',
                data: {
                    postcode: postcode
                }, 
                success : function (response, status) {
                    // console.log('lookup ', response);
                    // console.log('lookup ', status);
                    if ( status == "success" && (response != 0 && response != '0' && response != 'failed' ) ) { 
                        jQuery('.address-picker').html(response);
                        jQuery('.address').show();
                        jQuery('.question5').css('display', 'flex');
                        //address_looked_up = true;
                        //enable_postcode_submit_button();
    
                        if ( jQuery('#selected-address').length > 0 && jQuery('#selected-address').val() != '' ) {
                            // console.log(jQuery('#selected-address').val());
                            jQuery('.address-picker option:contains("' + jQuery('#selected-address').val() + '")').prop('selected', true);
                        }
                    } else if ( response == 0 ) {
                        jQuery('#postcode-error').html('Invalid postcode. Please try again.');
                        jQuery('#postcode-error').delay(1000).show();
                        //enable_postcode_submit_button();
                    } else {
                        
                    }
                }, error : function (response) {
                    //enable_postcode_submit_button();
                    console.log('lookup err ', response);
                }
            });
        }





    



        var day = "";
        var month = "";
        var year = "";
        var dob = "";

        jQuery('#day').on('change', function() {
            day = jQuery(this).val();
            dob = jQuery(this).val() + '/' + month + '/' + year;
            console.log(dob);
            
            jQuery('#dob').val(dob);
        });

        jQuery('#month').on('change', function() {
            month = jQuery(this).val();
            dob = day + '/' + jQuery(this).val() + '/' + year;
            console.log(dob);
            jQuery('#dob').val(dob);
        });

        jQuery('#year').on('change', function() {
            year = jQuery(this).val();
            dob = day + '/' + month + '/' + jQuery(this).val();
            console.log(dob);
            jQuery('#dob').val(dob);
        });


        // SUBMITTING
        var mobile_is_valid = false;
        var email_is_valid = false;
        console.log(jQuery('.life-form-submit-btn'));
    if ( jQuery('.life-form-submit-btn').length > 0 ) {
        console.log("here");
        
        jQuery('.life-form').each( function(e) {
            
            let form_name = jQuery(this).attr('name');
            console.log('form_name',form_name);
            
            check_input_validation_process('firstname', 'first name',form_name, false);
            check_input_validation_process('lastname', 'last name',form_name, false);
            check_input_validation_process('email', 'email address',form_name, false);
            check_input_validation_process('phone1', 'mobile number',form_name, false);


            check_input_validation('firstname', 'first name',form_name);
            check_input_validation('lastname', 'last name',form_name);
            check_input_validation('email', 'email address',form_name);
            check_input_validation('phone1', 'mobile number',form_name);
            
            
            if ( jQuery('#postcode').length > 0 ) {
                check_input_validation_process('postcode', 'postcode',form_name, false);
                check_input_validation('postcode', 'postcode',form_name);
            }
            if ( jQuery('#street1').length > 0 ) {
                check_input_validation_process('street1', 'address',form_name, false);
                check_input_validation('street1', 'address',form_name);
            }
        })


        function check_input_validation(field, label, form_name) {
            jQuery('#'+field).on('blur', function() {
                console.log('#field blur',field);
                check_input_validation_process(field,label,form_name, true)
            })
        }

        function check_input_validation_process(field,label,form_name, show_error = true) {
            hide_input_field_error(field,form_name); 
            if ( jQuery('#'+field).val() == "" ) {
                console.log('empty',form_name, field)
                if (!show_error) return;
                show_input_field_error(field, label, null, form_name);
            
            } else if ( field == 'email' ) {
                if ( !validate_email( jQuery('#email').val() ) ) {
                    email_is_valid = false;
                    if (!show_error) return;
                    show_input_field_error(field, label, 'Your email address is not in a valid format.', form_name);
                    jQuery('#a6-btn').hide();
                } else {
                    hide_input_field_error(field, form_name) 
                    email_is_valid = true;
                }
            } else if ( field == 'phone1' ) {
                let mobile_error_msg = 'Your mobile number is not in a valid format or you have entered it incorrectly.';
                jQuery.ajax({
                    url: 'https://api.mobilecheck.co.uk/?api_key=eacdce9d3a579642a911277b703fb5c3&tel=' + jQuery('#phone1').val(),
                    type: 'POST',
                    enctype: 'multipart/form-data',
                    data: {}, 
                    beforeSend: function () { // Before we send the request, remove the .hidden class from the spinner and default to inline-block.
                        jQuery('#loader').removeClass('hidden');
                    },
                    success : function (response) {
                        console.log('mobile ', jQuery('#phone1').val());
                        console.log('mobile ', response);
                        if ( response.status == "OK" ) {
                            mobile_is_valid = true;
                            jQuery('#a7-btn').removeClass('disabled');
                            jQuery('#finish').removeClass('disabled');
                            hide_input_field_error(field, form_name);
                            jQuery('#loader').addClass('hidden');
                        } else {
                            jQuery('#a7-btn').addClass('disabled');
                            jQuery('#finish').addClass('disabled');
                            mobile_is_valid = false;
                            if (!show_error) return;
                            show_input_field_error(field, label, mobile_error_msg, form_name);
                            jQuery('#loader').addClass('hidden');
                        }
                    }, error : function (response) {
                        mobile_is_valid = false;
                        show_input_field_error(field, form_name);
                    }
                });
            } else {
                hide_input_field_error(field, form_name);
            }
        }

        function show_input_field_error(field, label, error_msg = 'Please enter your', form_name) {
            if ( !error_msg ) error_msg =  'Please enter your ' + label;
            console.log('show error for',form_name, field)
            jQuery('#'+field).addClass('field-input-error');
            jQuery('#'+field+'-error').html(error_msg);
            jQuery('#'+field+'-error').show();
        }
        function hide_input_field_error(field, form_name) {
            jQuery('#'+field).removeClass('field-input-error');
            jQuery('#'+field+'-error').hide();
            jQuery('#'+field+'-error').html('');
        }



        var btn;
        var btn_text;
        if ( jQuery('.life-form-submit-btn').length > 0 ) {
            btn_text = jQuery('.life-form-submit-btn').html();
        }
        

        if ( jQuery('.terms-toggle-wrapper').length > 0 ) {
            btn = jQuery('.life-form-submit-btn');
            disable_submit_button(null);

            jQuery('.terms-toggle-btn').on('click', function() {
                console.log('toggle!!');
                let count_checked = 0;
                setTimeout( function() {
                    jQuery('input[name=terms-toggle]').each( function() {
                        if (jQuery(this).is(':checked')) {
                            count_checked++;
                        }
                    })
                    console.log('count_checked++;',count_checked)

                    if ( jQuery('input[name=terms-toggle]').is(':checked') && jQuery('input[name=terms-toggle]').length <= count_checked ) {
                        enable_submit_button();
                    } else {
                        disable_submit_button(null);
                    }
                },500);
            });
        }

        jQuery()
        
        
        // -------------------------
        // On submit
        // -------------------------
        if (jQuery('.life-form-submit-btn').length > 0) {

            jQuery('.life-form-submit-btn').each( function() {
                let form_name = jQuery(this).data('form');
                console.log('lets check....',form_name);
                // let's check to see if email is valid
                if ( jQuery('#email').val() ) {
                    console.log('check email....');
                    // email_is_valid = check_if_email_is_valid();
                    check_input_validation_process('email', 'email address', form_name);
                }
                // let's check if mobile is valid
                if ( jQuery('#phone1').val() ) {
                    console.log('check phone1....');
                    check_input_validation_process('phone1', 'mobile number', form_name);
                }
            })
        }

        var processing_form = false;
        jQuery('.life-form-submit-btn').on('click', function(e) {
            
            if (processing_form) return;
            offer_form_hide_error();
            set_form_processing_status(true);

            let form_name = jQuery(this).data('form');
            btn = jQuery(this);
            btn_text = btn.html();

            e.preventDefault();

            var c1 = Jdebt;
            var c2 = Jdebts;
            var c3 = Jincome;


            jQuery('#c1').val(c1);
            jQuery('#c2').val(c2);
            jQuery('#c3').val(c3);

            let arr = [
                'firstname',
                'lastname',
                'phone1',
                'email',
            ];

            if ( jQuery('#postcode').length > 0 ) {
                arr.push('postcode');
            }
            if ( jQuery('#street1').length > 0 ) {
                arr.push('street1');
            }

            if ( jQuery('#dob').length > 0 ) {
                arr.push('dob');
            }
            
            let has_blanks = false;
            console.log('Form', form_name)
            for ( let i = 0; i < arr.length; i++) {
                if ( jQuery('#' + arr[i]).val() == "" ) {
                    has_blanks = true;
                    console.log('has blanks', arr[i])
                    
                    console.log(arr[i] + ' length:', jQuery('#' + arr[i]).val() );
                    console.log(arr[i] + ' value:', jQuery('#' + arr[i]).length );

                    offer_form_show_error('This field cannot be blank.',form_name, arr[i]);
                    set_form_processing_status(false);
                }
            }

            if ( has_blanks ) {
                set_form_processing_status(false);
                return;
            }

            // check if dob exists 
            if ( jQuery('#dob').length > 0 ) {
                if ( jQuery('#dob').val().length < 10 ) {
                    offer_form_show_error('Your date of birth is incomplete.',form_name, 'dob');
                    set_form_processing_status(false);
                    return;
                }
            }

            // check if email is valid
            email_is_valid = check_if_email_is_valid(form_name);
            
            mobile_is_valid = check_if_number_is_mobile(form_name);
            if (!mobile_is_valid) {
                console.log('invalid mobile');
                let mobile_error_msg = 'Your mobile number is not a recognised UK phone number, which should start with 07 or +447.';
                show_input_field_error('phone1', 'Phone', mobile_error_msg, form_name);
                set_form_processing_status(false);
                return;
            }

            if ( jQuery('.terms-toggle-wrapper').length > 0 ) {
                if ( !jQuery('input[name=terms-toggle]').is(':checked') ) {
                    offer_form_terms_show_error('You have to accept the terms and conditions before submitting.',form_name);
                    set_form_processing_status(false);
                    return;
                } else {
                    offer_form_terms_hide_error();
                }
            }
            
            if ( email_is_valid && mobile_is_valid) {
                console.log('lets submit')
                
                //disable_submit_button();
                offer_form_hide_error();
                submit_offer_form(form_name, btn);
                return;
            }
            set_form_processing_status(false);
        });

        function set_form_processing_status(status) {
            processing_form = status;
        }

        function check_if_email_is_valid(form_name) {
            if ( validate_email( jQuery('#email').val() ) ) {
                return true;
            }
            return false;
        }

        function check_if_number_is_mobile(form_name) {
            if ( validate_mobile( jQuery('#phone1').val() ) ) {
                return true;
            } 
            return false;
        }

        function disable_submit_button(action = 'send') {
            if ( action == 'send' ) {
                btn.html(btn_submit_text);
            }
            btn.attr('disabled','disabled');
            btn.addClass('btn-disabled');
        }
        
        function enable_submit_button() {
            btn.html(btn_text);
            btn.removeAttr('disabled');
            btn.removeClass('btn-disabled');
        }

        function offer_form_show_error(msg, form_name, field) {
            jQuery('#'+field+'-error').show();
            jQuery('#'+field+'-error').html(msg);
        }
        function offer_form_terms_show_error(msg, form_name) {
            jQuery('.life-form-terms-error').html(msg);
            jQuery('.life-form-terms-error').show();
        }
        
        function offer_form_terms_hide_error() {
            jQuery('.life-form-terms-error').hide();
        }
        function offer_form_hide_error() {
            jQuery('.life-form-error').hide();
            offer_form_terms_hide_error();
        }

        function validate_email(email) {
            console.log(email)
            // const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
            {
                console.log('email valid');
                return true;
            }
            console.log('email invalid');
            return false;
        }
        
        function validate_mobile(mobile) {
            console.log('phone',mobile);
            let mobile_number =  mobile + '';
            var mobileNumberRegex = /^(?:(?:00)?44|0)7(?:[45789]\d{2}|624)\d{6}$/;
            // if (mobile_number.replace(/^\s+|\s+$/g,'').test(mobileNumberRegex)) {
            if (mobile.match(/^((\+?44\s?|0)7([45789]\d{2}|624)\s?\d{3}\s?\d{3})$/) ) {
                console.log('mobile valid');
                return true;
            }
            console.log('mobile invalid');
            jQuery('#a7-btn').hide();
            return false;
            // jQuery.validator.addMethod('mobileUK', function(mobile, element) {
            //     return this.optional(element) || phone_number.length > 9 &&
            //         phone_number.match(/^((\+?44\s?|0)7([45789]\d{2}|624)\s?\d{3}\s?\d{3})$/);
            // }, 'Please specify a valid mobile number');
        }

        

        function submit_offer_form(form_name, btn) {

            jQuery('#finish i').show();

            let data = {
                campid : jQuery('#campid').val() || 0,
                sid : jQuery('#sid').val() || 0,
                postcode : jQuery('#postcode').val() || 0,
                ssid : jQuery('#ssid').val() || 0,
                c1 : Jdebt || 0,
                c2 : Jdebts || 0,
                c3 : Jincome || 0,
                c4 : jQuery('#c4').val() || 0,
                c5 : jQuery('#c5').val() || 0,
                c6 : jQuery('#c6').val() || 0,
                source : jQuery('#source').val() || 0,
                firstname : jQuery('#firstname').val() || 0,
                lastname : jQuery('#lastname').val() || 0,
                email : jQuery('#email').val() || 0,
                gclid : jQuery('#gclid').val() || 0,
                msclkid : jQuery('#msclkid').val() || 0,
                phone1 : jQuery('#phone1').val() || 0,
                street1 : jQuery('.address-picker').val() || 0,
                industry : jQuery('#industry').val() || 0,
                dob : jQuery('#dob').val() || 0
            }
            console.log('data',data);
            // return;
            jQuery.ajax({
                url: 'https://api.switcheroo.deals/leadbyte-form-submit-v4.php?' + 'campid='+data.campid+'&sid=' + data.sid + '&postcode=' + data.postcode + '&ssid=' + data.ssid + '&c1=' + data.c1 + '&c2=' + data.c2 + '&c3=' + data.c3 + '&c4=' + data.c4 + '&c5=' + data.c5 + '&c6=' + data.c6 + '&source=' + data.source + '&firstname=' + data.firstname + '&lastname=' + data.lastname + '&email=' + data.email + '&gclid=' + data.gclid + '&msclkid=' + data.msclkid + '&phone1=' + data.phone1 + '&street1=' + data.street1 + '&industry=' + data.industry + '&dob=' + data.dob,
                type: 'POST',
                enctype: 'multipart/form-data',
                data: data,
                success : function (response) {
                    console.log('success',response);
                    const newc1 = jQuery('#c1').val();
                    const newc2 = jQuery('#c2').val();
                    const newc3 = jQuery('#c3').val();

                    const redirect = btn.data('redirect') + "?debt=" + newc1 + "&monthly=" + newc3;
                    if (redirect) {
                        window.location.replace( redirect );
                        return;
                    } 
                    jQuery('#life-form').submit();
                
                },error : function (response) {
                    console.log('error',response);
                    // something went wrong
                    enable_submit_button();
                    offer_form_show_error('Something went wrong. Please try again.', form_name);
                    set_form_processing_status(false);
                }});
        }

    }
        


    });

})(jQuery, this);